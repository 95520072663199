<template>
    <div class="w-64 mx-auto mt-4 ">
        <dropdown v-model="reason" :options="archiveReasonsOptions"></dropdown>
        <span v-if="errorsBag.length" class="form-error">{{ showError('reason') }}</span>
    </div>
</template>

<script>
    import Dropdown from "@/components/ui/Dropdown";
    import { archiveReasonsOptions } from "@/utils/GuestCards";

    export default {
        components: { Dropdown },
        data: () => {
            return {
                reason: null,
                archiveReasonsOptions: archiveReasonsOptions
            }
        },
        watch: {
            reason: {
                handler: function() { this.loadCustomComponentData( { reason: this.reason } ) },
                immediate: true
            }
        },
        validations: {
            reason: 'required'
        }
    }
</script>